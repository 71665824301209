// RequestNewPasswordPage.js
import React from 'react';
import Login from './Login';
import RequestNewPasswordForm from './RequestNewPasswordForm';
import { withStyles } from '@material-ui/core/styles';

const styles = ({
    main: { background: '#333' },
});

const CustomNewPasswordForm = withStyles({
    button: { background: '#F15922' },
})(RequestNewPasswordForm);

const CustomNewPasswordPage = props => (
    <Login backgroundImage={false}
        loginForm={<CustomNewPasswordForm />}
        {...props}
    />
);

export default withStyles(styles)(CustomNewPasswordPage);
