// in src/accounts.js
import React from 'react';
import {
  Show, Edit, TextField, ReferenceField, EmailField, DateField,
  TextInput, SimpleForm, BooleanField, SimpleShowLayout, ReferenceArrayField, SingleFieldList, ChipField
} from 'react-admin';
import {
  required,
  email,
  minLength,
} from 'react-admin';
import { Toolbar, SaveButton } from 'react-admin';

export const AccountShow = ({ permissions, ...props }) => (
  <Show title="Mis Datos" {...props}>
    <SimpleShowLayout>
      <TextField source="user.first_name" label="Nombre" />
      <TextField source="user.last_name" label="Apellido" />
      <EmailField source="user.email" label="correo FAUBA" />
      <ReferenceArrayField linkType={false} reference="accounttypes" source="account_types_id" label="Tipo de Cuenta">
        <SingleFieldList>
          <ChipField source="name" />
        </SingleFieldList>
      </ReferenceArrayField>
      <ReferenceField linkType={false} source="id_type_id" reference="idtypes" label="Tipo de Documento">
        <TextField source="name" />
      </ReferenceField>
      <TextField source="id_number" label="Documento N." />
      <EmailField source="alternative_email" label="Email alternativo" />
      <BooleanField source="alternative_email_active" label="Email alternativo confirmado" />
      <TextField source="phone" label="Teléfono" />
      <DateField source="created" label="Creado" locales="es-ES" />
    </SimpleShowLayout>
  </Show>
);

const PostEditToolbar = props => (
  <Toolbar {...props} >
    <SaveButton />
  </Toolbar>
);

const oneLowerCaseLetter = (value) => {
  if (typeof value != 'string') {
    return undefined
  }
  const re = new RegExp("[a-z]+");
  const a = value.match(re)
  if (a == null) {
    return 'la contraseña debe contener un letra minuscula'
  }
  return undefined
}

const oneUpperCaseLetter = (value) => {
  if (typeof value != 'string') {
    return undefined
  }
  const re = new RegExp("[A-Z]+");
  const a = value.match(re)
  if (a == null) {
    return 'la contraseña debe contener un letra mayuscula'
  }
  return undefined
}

const oneDigit = (value) => {
  if (typeof value != 'string') {
    return undefined
  }
  const re = new RegExp("[0-9]+");
  const a = value.match(re)
  if (a == null) {
    return 'la contraseña debe contener un número'
  }
  return undefined
}

const validateEmail = [email("No es un email válido"), required("Requerido")];
const validateRequired = required("Requerido");
const validatePassword = [minLength(8, "Debe tener al menos 8 caracteres"), oneLowerCaseLetter, oneUpperCaseLetter, oneDigit];

const validatePasswordConfirm = (value, allValues) => {
  if (!(value === allValues.password)) {
    return 'Las contraseñas ingresadas no coinciden';
  }
  return undefined;
};


export const AccountsEdit = props => (
  <Edit title="Editando Mis Datos" {...props}>
    <SimpleForm toolbar={<PostEditToolbar />}>
      <TextInput source="user.first_name" label="Nombre" validate={validateRequired} />
      <TextInput source="user.last_name" label="Apellido" validate={validateRequired} />
      <TextInput source="alternative_email" label="Email alternativo" type="email"
        helperText="Se enviará un correo a esta dirección para confirmar el cambio"
        validate={validateEmail} />
      <TextInput source="phone" label="Teléfono" />
      <TextInput label="Nueva contraseña" source="password" type="password" validate={validatePassword} />
      <TextInput label="Confirmar nueva contraseña" source="password_confirm" type="password" validate={validatePasswordConfirm} />
    </SimpleForm>
  </Edit>
);
