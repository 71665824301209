import React, { Component } from 'react';
import {
    SimpleForm,
    TextField,
    ReferenceField,
    FormDataConsumer,
    Edit,
    TextInput,
    DisabledInput,
    Toolbar,
    SaveButton,
    required,
    crudUpdate,
    Labeled
} from 'react-admin';
import { connect } from 'react-redux';

/*
const editStyle = theme => ({
    root: {
        paddingBottom: 15,
    },
    title: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        margin: '1em',
    },
    form: {
        [theme.breakpoints.up('xs')]: {
            width: 400,
        },
        [theme.breakpoints.down('xs')]: {
            width: '100vw',
            marginTop: -30,
        },
    },
    inlineField: {
        display: 'inline-block',
        width: '50%',
    },
});

const SubscriptionEdit = ({ classes, onCancel, ...props }) => (
    <EditController {...props}>
        {controllerProps =>
            controllerProps.record ? (
                <div className={classes.root}>
                    <SimpleForm
                        className={classes.form}
                        basePath={controllerProps.basePath}
                        record={controllerProps.record}
                        save={controllerProps.save}
                        version={controllerProps.version}
                        redirect="list"
                        resource="subscriptions"
                        toolbar={<ActionsBar />}
                    >
                        <ReferenceField linkType={false} source="service_id" reference="services" label="">
                          <TextField source="description" />
                        </ReferenceField>
                        <FormDataConsumer>
                          {({ formData, ...rest }) => formData.note &&
                              <TextField label="nota" source="note" rowsMax={15} helperText="Lo ve el usuario" {...rest} />
                          }
                        </FormDataConsumer>
                    </SimpleForm>
                </div>
            ) : null
        }
    </EditController>
);

export default withStyles(editStyle)(SubscriptionEdit);
*/

// A custom action creator which modifies the values before calling the default crudCreate action creator
const saveWithTransition = (values, id, basePath, redirectTo, transition) =>
    crudUpdate('subscriptions', id, { ...values, transition: transition }, undefined, basePath, false);

class SaveWithTransitionButtonView extends Component {
    handleClick = () => {
        const { basePath, handleSubmit, redirect, saveWithTransition, transition } = this.props;
        console.log(this.props);

        return handleSubmit(values => {
            saveWithTransition(values, this.props.record.id, basePath, redirect, transition);
        });
    };

    render() {
        const { handleSubmitWithRedirect, saveWithTransition, transition,  ...props } = this.props;

        return (
            <SaveButton
                handleSubmitWithRedirect={this.handleClick}
                {...props}
            />
        );
    }
}

const SaveWithTransition = connect(
    undefined,
    { saveWithTransition }
)(SaveWithTransitionButtonView);

const validateRequired = required("Requerido");

const ConditionalNoteField = ({ record, ...rest }) =>
    record && record.note
        ? (
          <Labeled label="Mensaje">
            <TextField source="note" record={record} {...rest} />
          </Labeled>
        )
        : null;

export const SubscriptionEdit = props => (
    <Edit
        {...props}
        /* disable the app title change when shown */
        title=" "
        undoable={false}
    >
        <SimpleForm
            /* The form must have a name dependent on the record, because by default all forms have the same name */
            form={`post_edit_${props.id}`}
            toolbar={<SubscriptionEditToolbar />}
        >
            <ReferenceField linkType={false} source="service_id" reference="services" label="">
              <TextField source="description" />
            </ReferenceField>
            <FormDataConsumer>
              {({ formData, ...rest }) =>
              (props.record.state_id === 2
                || props.record.state_id === 8)
                && userHasPermission(formData.service_id, 2) ?
                  <TextInput source="dynamic_fields.motive" label="Justificativo" validate={validateRequired} {...rest} />
              :
              (props.record.state_id === 1
                || props.record.state_id === 5)
                && userHasPermission(formData.service_id, 4) ?
                  <TextInput source="dynamic_fields.motive" label="Justificativo" validate={validateRequired} {...rest} />
              : <DisabledInput source="dynamic_fields.motive" label="Justificativo" />
              }
            </FormDataConsumer>
            <ConditionalNoteField label="Mensaje" />
        </SimpleForm>
    </Edit>
);

function userHasPermission(service_id, permission) {
  const services_permissions = JSON.parse(localStorage.getItem('services_permissions'));
  if (!(service_id in services_permissions)) {
    return false;
  }
  return services_permissions[service_id].includes(permission);
}

const SubscriptionEditToolbar = props => (
    <Toolbar {...props} >
      {(props.record.state_id === 1 || props.record.state_id === 5)
        && userHasPermission(props.record.service_id, 3) ?
          <SaveWithTransition
              label="Desactivar"
              redirect={false}
              submitOnEnter={false}
              variant="flat"
              transition="deactivate"
          /> : (props.record.state_id === 1 || props.record.state_id === 5)
          && userHasPermission(props.record.service_id, 4) ?
          <SaveWithTransition
              label="Solicitar desactivación"
              redirect={false}
              submitOnEnter={false}
              variant="flat"
              transition="deactivate"
          /> : false
      }

     {(props.record.state_id ===  2 || props.record.state_id ===  8)
        && userHasPermission(props.record.service_id, 1) ?
         <SaveWithTransition
             label="Activar"
             redirect={false}
             submitOnEnter={false}
             variant="flat"
             transition="activate"
         /> : (props.record.state_id ===  2 || props.record.state_id ===  8)
         && userHasPermission(props.record.service_id, 2) ?
         <SaveWithTransition
             label="Solicitar activación"
             redirect={false}
             submitOnEnter={false}
             variant="flat"
             transition="activate"
         /> : false
      }

    </Toolbar>
);
