// in src/dataProvider
import {
    GET_LIST,
    GET_ONE,
    GET_MANY,
    GET_MANY_REFERENCE,
    CREATE,
    UPDATE,
    DELETE,
    HttpError,
} from 'react-admin';
import { stringify } from 'query-string';

const API_URL = process.env.REACT_APP_API_URL;

function getCookie(name) {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
}


/**
 * @param {String} type One of the constants appearing at the top if this file, e.g. 'UPDATE'
 * @param {String} resource Name of the resource to fetch, e.g. 'posts'
 * @param {Object} params The Data Provider request params, depending on the type
 * @returns {Object} { url, options } The HTTP request parameters
 */
const convertDataProviderRequestToHTTP = (type, resource, params) => {
    var asc_or_desc;
    /*for (var i in params.ids) {
        var url_array = params.ids[i].split('/');
        params.ids[i] = url_array[url_array.length - 2];
    }*/
    switch (type) {
        case GET_LIST: {
            const { page, perPage } = params.pagination;
            const { field, order } = params.sort;
            if (order === "ASC") {
                asc_or_desc = "";
            } else { asc_or_desc = "-"; }
            var query = {
                ordering: asc_or_desc.concat(field.replace(".", "__")),
                limit: perPage,
                offset: (page - 1) * perPage,
                search: params.filter.search,
            };
            return { url: `${API_URL}/${resource}/?${stringify(query) + '&' + stringify(params.filter)}` };
        }
        case GET_ONE:
            return { url: `${API_URL}/${resource}/${params.id}/` };
        case GET_MANY: {
            const query = {
                id: params.ids.join(),
            };
            return { url: `${API_URL}/${resource}/?${stringify(query)}` };
        }
        case GET_MANY_REFERENCE: {
            const { page, perPage } = params.pagination;
            const { field, order } = params.sort;
            if (order === "ASC") {
                asc_or_desc = "";
            } else { asc_or_desc = "-"; }
            var query = {
                ordering: asc_or_desc.concat(field.replace(".", "__")),
                limit: perPage,
                offset: (page - 1) * perPage,
                search: params.filter.search
            };

            return { url: `${API_URL}/${resource}/?${stringify(query) + '&' + stringify(params.filter) + params.target + '=' + params.id}` };
        }
        case UPDATE:
            if (resource === 'accounts') {
                params.data.user.password = params.data.password;
            }
            console.log('UPDATE')
            console.log(params)
            return {
                url: `${API_URL}/${resource}/${params.id}/`,
                options: { method: 'PUT', body: JSON.stringify(params.data) },
            };
        case CREATE:
            //params.data.account = API_URL + '/' + params.data.account_id + '/';
            var body = params.data;

            if (resource === 'subscriptions') {
                params.data.account = `${API_URL}/accounts/${params.data.account_id}/`;
                params.data.service = `${API_URL}/services/${params.data.service_id}/`;
            }

            return {
                url: `${API_URL}/${resource}/`,
                options: { method: 'POST', body: JSON.stringify(body) },
            };
        case DELETE:
            return {
                url: `${API_URL}/${resource}/${params.id}/`,
                options: { method: 'DELETE' },
            };
        default:
            throw new Error(`Unsupported fetch action type ${type}`);
    }
};

/**
 * @param {Object} response HTTP response from fetch()
 * @param {String} type One of the constants appearing at the top if this file, e.g. 'UPDATE'
 * @param {String} resource Name of the resource to fetch, e.g. 'posts'
 * @param {Object} params The Data Provider request params, depending on the type
 * @returns {Object} Data Provider response
 */
const convertHTTPResponseToDataProvider = (response, type, resource, params) => {
    var { headers, json } = response;
    /*for (var i in json.results) {
        var url_array = json.results[i].url.split('/')
        json.results[i].id = url_array[url_array.length - 2];
    }*/
    console.log(type)
    switch (type) {
        case GET_LIST:
            return {
                data: json.results.map(x => x),
                total: parseInt(json.count, 10),
            };
        case GET_MANY:
            return {
                data: json.results,
            };
        case GET_MANY_REFERENCE:
            return {
                data: json.results,
                total: json.count
            };
        case CREATE:
            return { data: { ...params.data, id: json.id } };
        default:
            return { data: json };
    }
};

const improveErrorMessage = (obj) => {
    if (typeof obj === 'string') {
        return obj
    }
    if (obj.user) {
        if (obj.user.password) {
            return 'La contraseña debe tener 8 caracteres minimos, una mayuscula, una minuscula y un digito, no debe contener el nombre de usuario.'
        }
        return JSON.stringify(obj.user)
    }
    return JSON.stringify(obj)
}

export const fetchJson = (url, options) => {
    const requestHeaders = options.headers;
    if (
        !requestHeaders.has('Content-Type') &&
        !(options && options.body && options.body instanceof FormData)
    ) {
        requestHeaders.set('Content-Type', 'application/json');
    }
    if (options.user && options.user.authenticated && options.user.token) {
        requestHeaders.set('Authorization', options.user.token);
    }

    return fetch(url, { ...options, headers: requestHeaders })
        .then(response =>
            response.text().then(text => ({
                status: response.status,
                statusText: response.statusText,
                headers: response.headers,
                body: text,
            }))
        )
        .then(({ status, statusText, headers, body }) => {
            let json;
            try {
                json = JSON.parse(body);
            } catch (e) {
                // not json, no big deal
            }
            if (status < 200 || status >= 300) {
                let message = statusText
                if (json) {
                    message = improveErrorMessage(json)
                }
                return Promise.reject(
                    new HttpError(
                        message,
                        status,
                        json
                    )
                );
            }
            return Promise.resolve({ status, headers, body, json });
        })
};

/**
 * @param {string} type Request type, e.g GET_LIST
 * @param {string} resource Resource name, e.g. "posts"
 * @param {Object} payload Request parameters. Depends on the request type
 * @returns {Promise} the Promise for response
 */
export default (type, resource, params) => {
    //const { fetchJson } = fetchUtils;
    var { url, options } = convertDataProviderRequestToHTTP(type, resource, params);
    if (!options) {
        options = {};
    }
    if (!options.headers) {
        options.headers = new Headers({ Accept: 'application/json' });
    }
    options.headers.set('Authorization', 'Bearer ' + localStorage.getItem('access_token'));
    options.headers.set('X-CSRFToken', getCookie('csrftoken'));

    // Lo siguiente va en prod

    return fetchJson(url, options)
        .then(response => convertHTTPResponseToDataProvider(response, type, resource, params));
};
