// in src/MyMenu.js
import React from 'react';
import { connect } from 'react-redux';
import { MenuItemLink, getResources, Responsive } from 'react-admin';
import { withRouter } from 'react-router-dom';
import UserIcon from '@material-ui/icons/People';
import FingerprintIcon from '@material-ui/icons/Fingerprint';
import RoomServiceIcon from '@material-ui/icons/RoomService';
import { SubscriptionsList } from './subscriptions';


const MyMenu = ({ resources, onMenuClick, logout }) => (
    <div>
        <MenuItemLink to={"/accounts/"+localStorage.getItem('account_id')+"/show"}
          primaryText="Mis datos" onClick={onMenuClick} leftIcon={<UserIcon />} />
        <MenuItemLink to={"/accounts/"+localStorage.getItem('account_id')}
          primaryText="Cambio de clave" onClick={onMenuClick} leftIcon={<FingerprintIcon />} />
        <MenuItemLink to="/subscriptions"
          primaryText="Servicios" onClick={SubscriptionsList} leftIcon={<RoomServiceIcon />} />
        <Responsive
            small={logout}
            medium={null} // Pass null to render nothing on larger devices
        />
    </div>
);

const mapStateToProps = state => ({
    resources: getResources(state),
});

export default withRouter(connect(mapStateToProps)(MyMenu));
