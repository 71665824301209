// in src/comments/ApproveButton.js
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
//import Button from '@material-ui/core/Button';
import { subscriptionCreate } from './subscriptionsActions';


class ServicesBar extends Component {

    handleSubmit = () => {
      const { subscriptionCreate, record } = this.props;
      const new_subscription = { service_id: record.id, account_id: localStorage.getItem('account_id') };
      subscriptionCreate(new_subscription);
    }

    render() {
        const { record } = this.props;

        const services_permissions = (JSON.parse(localStorage.getItem('services_permissions')));

        if (services_permissions[record.id] === undefined) {
          // No tiene ningún permiso para este servicio
          return(null);
        }
        /*
        if (services_permissions[record.id].includes(1)) {
          return(<Button variant='outlined' label='Enable' color='primary' onClick={this.handleSubmit}> Dar de alta </ Button>);
        } else if (services_permissions[record.id].includes(2)) {
          return(<Button variant='outlined' label='Enable' color='primary' href={'#/subscriptions/create/?service_id=' + record.id}> Solicitar </ Button>);
        }*/

        return(null);
    }
}

ServicesBar.propTypes = {
    record: PropTypes.object,
    subscriptionCreate: PropTypes.func.isRequired,
};

export default connect(null, {
    subscriptionCreate
})(ServicesBar);
