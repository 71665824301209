// in src/comment/subscriptionsUpdate.js
import { UPDATE, CREATE } from 'react-admin';

export const SUBSCRIPTION_ACTIVATE = 'SUBSCRIPTION_ACTIVATE';
export const SUBSCRIPTION_DEACTIVATE = 'SUBSCRIPTION_DEACTIVATE';
export const SUBSCRIPTION_CREATE = 'SUBSCRIPTION_CREATE';

export const subscriptionActivate = (id, data, basePath) => ({
    type: SUBSCRIPTION_ACTIVATE,
    payload: { id, data: { ...data, transition: 'activate' } },
    meta: {
      fetch: UPDATE,
      resource: 'subscriptions',
      notification: {
           body: 'Acción realizada',
           level: 'info',
      },
      refresh: true,
    },
});

export const subscriptionDeactivate = (id, data, basePath) => ({
    type: SUBSCRIPTION_DEACTIVATE,
    payload: { id, data: { ...data, transition: 'deactivate' } },
    meta: {
      fetch: UPDATE,
      resource: 'subscriptions',
      notification: {
           body: 'Acción realizada',
           level: 'info',
      },
      refresh: true,
    },
});

export const subscriptionCreate = (data, basePath) => ({
    type: SUBSCRIPTION_CREATE,
    payload: { data: { ...data } },
    meta: {
      fetch: CREATE,
      resource: 'subscriptions',
      notification: {
           body: 'Acción realizada',
           level: 'info',
      },
      refresh: true,
    },
});
