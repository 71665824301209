// LoginPage.js
import React from 'react';
import Login from './Login';
import LoginForm from './LoginForm';
import { withStyles } from '@material-ui/core/styles';

const styles = ({
    main: { background: '#333', backgroundImage: "false" },
});

const CustomLoginForm = withStyles({
    button: { background: '#F15922' },
})(LoginForm);

const CustomLoginPage = props => (
    <Login backgroundImage={'background.jpg'}
        loginForm={<CustomLoginForm />}
        {...props}
    />
);

export default withStyles(styles)(CustomLoginPage);
