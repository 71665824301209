// in src/Dashboard.js
import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { Title } from 'react-admin';
export default () => (
    <Card>
        <Title title="Panel de administración de cuenta FAUBA" />
        <CardContent>
          <Typography>
            Seleccione una opción del menú para comenzar.
          </Typography>
        </CardContent>
    </Card>
);
