import React from 'react';
import {
  List,
  TextField,
  Datagrid,
} from 'react-admin';
import ServicesBar from './ServicesBar';

export const ServicesList = props => (
    <List {...props}
      filter={{ is_active: true, available_to_self_subscribe: localStorage.getItem("account_id") }}
      title="Servicios"
      bulkActionButtons={false}
      exporter={false}
      >
        <Datagrid>
            <TextField source="description" label="Servicio" />
            <ServicesBar/>
        </Datagrid>
    </List>
);
