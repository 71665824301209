import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Field, propTypes, reduxForm } from 'redux-form';
import compose from 'recompose/compose';
import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';
import { withStyles, createStyles } from '@material-ui/core/styles';
import InputAdornment from '@material-ui/core/InputAdornment';
import { translate } from 'ra-core';
import { showNotification } from 'react-admin';
import { push } from 'react-router-redux';

const styles = () => createStyles({
    form: {
        padding: '0 1em 1em 1em',
    },
    input: {
        marginTop: '1em',
    },
    button: {
        width: '100%',
    },
});

// see http://redux-form.com/6.4.3/examples/material-ui/
const renderInput = ({
    meta: { touched, error } = { touched: false, error: '' }, // eslint-disable-line react/prop-types
    input: { ...inputProps }, // eslint-disable-line react/prop-types
    ...props
}) => (
    <TextField
        error={!!(touched && error)}
        helperText={touched && error}
        {...inputProps}
        {...props}
        fullWidth
    />
);

const RequestNewPasswordPage = ({ classes, isLoading, handleSubmit, translate }) => {

    const [loading, setLoading] = useState(false);

    const requestTempToken = async (auth, dispatch) => {
        setLoading(true);
        try {
            const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/password/forgot/`, { method: 'POST', body: JSON.stringify(auth) })
            const jsonResponse = await response.json();
            var notificationType = "warning";
            if (jsonResponse.message === "Se ha enviado un correo electrónico a su email alternativo para continuar el proceso") {
                notificationType = "info";
            }
            if (response.status == 200) {
                dispatch(push('/login'));
            }
            dispatch(showNotification(jsonResponse.message, notificationType));
        } finally {
            setLoading(false);
        }
    }

    return (
        <form onSubmit={handleSubmit(requestTempToken)}>
            <div className={classes.form}>
                <div className={classes.input}>
                    <Field
                        autoFocus
                        id="username"
                        name="username"
                        component={renderInput}
                        label={translate('ra.auth.username')}
                        disabled={loading}
                        InputProps={{
                            endAdornment: <InputAdornment position="end">@agro.uba.ar</InputAdornment>,
                        }}
                    />
                </div>
            </div>
            <CardActions>
                <Button
                    variant="raised"
                    type="submit"
                    color="primary"
                    disabled={loading}
                    className={classes.button}
                >
                    {isLoading && <CircularProgress size={25} thickness={2} />}
                    Recuperar mi clave
                </Button>
            </CardActions>
        </form>
    )
};
RequestNewPasswordPage.propTypes = {
    ...propTypes,
    classes: PropTypes.object,
    redirectTo: PropTypes.string,
};

const enhance = compose(
    withStyles(styles),
    translate,
    reduxForm({
        form: 'passwordRecover',
        validate: (values, props) => {
            const errors = {};
            const { translate } = props;
            if (!values.username)
                errors.username = translate('ra.validation.required');
            return errors;
        },
    })
);

export default enhance(RequestNewPasswordPage);
