import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Field, propTypes, reduxForm } from 'redux-form';
import compose from 'recompose/compose';
import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';
import { withStyles, createStyles } from '@material-ui/core/styles';
import { translate } from 'ra-core';
import { Link } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import { showNotification } from 'react-admin';
import { push } from 'react-router-redux';


const styles = () => createStyles({
    form: {
        padding: '0 1em 1em 1em',
    },
    input: {
        marginTop: '1em',
    },
    button: {
        width: '100%',
    },
});

// see http://redux-form.com/6.4.3/examples/material-ui/
const renderInput = ({
    meta: { touched, error } = { touched: false, error: '' }, // eslint-disable-line react/prop-types
    input: { ...inputProps }, // eslint-disable-line react/prop-types
    ...props
}) => (
    <TextField
        error={!!(touched && error)}
        helperText={touched && error}
        {...inputProps}
        {...props}
        fullWidth
    />
);

const ForgotPasswordPage = ({ classes, handleSubmit, translate }) => {

    const [loading, setLoading] = useState(false);

    const changePassword = async (auth, dispatch) => {
        setLoading(true);
        try {
            const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/password/change/`, { method: 'POST', body: JSON.stringify(auth) })
            const jsonResponse = await response.json()
            var notificationType = "warning";
            if (jsonResponse.message === "Contraseña cambiada") {
                notificationType = "info";
            }
            if (response.status == 200) {
                dispatch(push('/login'));
            }
            dispatch(showNotification(jsonResponse.message, notificationType));
        } finally {
            setLoading(false);
        }
    };

    return (
        <form onSubmit={handleSubmit(changePassword)}>
            <div className={classes.form}>
                <div className={classes.input}>
                    <Field
                        autoFocus
                        id="password"
                        name="password"
                        component={renderInput}
                        disabled={loading}
                        label={translate('ra.auth.password')}
                        type="password"
                    />
                </div>
                <div className={classes.input}>
                    <Field
                        id="password_confirm"
                        name="password_confirm"
                        component={renderInput}
                        disabled={loading}
                        label="Confirmar Password"
                        type="password"
                    />
                </div>
            </div>
            <CardActions>
                <Button variant="raised" type="submit" color="primary" disabled={loading} className={classes.button}>
                    {loading && <CircularProgress size={25} thickness={2} />} &nbsp; Cambiar clave</Button>
                <Link to={{ pathname: "/login" }} >
                    <Typography>
                        Acceder
                    </Typography>
                </Link>
            </CardActions>
        </form>
    )
};
ForgotPasswordPage.propTypes = {
    ...propTypes,
    classes: PropTypes.object,
    redirectTo: PropTypes.string,
};

const enhance = compose(
    withStyles(styles),
    translate,
    reduxForm({
        form: 'passwordRecover',
        validate: (values, props) => {
            const errors = {};
            const { translate } = props;
            if (!values.password)
                errors.password = translate('ra.validation.required');
            if (values.password && values.password.length < 8)
                errors.password = "Debe tener al menos 8 caracteres";
            if (!values.password_confirm)
                errors.password_confirm = translate('ra.validation.required');
            if (values.password !== values.password_confirm)
                errors.password_confirm = "Las contraseñas no coinciden";
            return errors;
        }
    })
);

export default enhance(ForgotPasswordPage);