import React from 'react';
import {
  Create,
  SimpleForm,
  List,
  Datagrid,
  TextField,
  ReferenceField,
  DateField,
  Filter,
  TextInput,
  BooleanInput,
  FunctionField,
  required
} from 'react-admin';
import Chip from '@material-ui/core/Chip';
import { CardActions, CreateButton } from 'react-admin';
import { parse } from 'query-string';
import rowStyle from './rowStyle';
import { SubscriptionEdit } from './SubscriptionEdit';

const SubscriptionsFilter = props => (
    <Filter {...props}>
        <TextInput label="Buscar" source="search" alwaysOn />
        <BooleanInput label="En espera" source="state__awaiting_for_admin"/>
    </Filter>
);

export const SubscriptionsCreate = props => {
  const { service_id: service_id_string } = parse(props.location.search);
  const service_id = service_id_string ? parseInt(service_id_string, 10) : '';

  const redirect = service_id ? `/subscriptions` : false;

  const account_id = localStorage.getItem('account_id');

  const validateRequired = required("Requerido");

  return (
    <Create {...props} title="Agregar un servicio" >
      <SimpleForm
        defaultValue={{ account_id: localStorage.getItem('account_id'), service_id: service_id }}
        redirect={redirect}
      >
        { account_id !== '' &&
          <TextInput label="Justificativo" validate={validateRequired} />
        }
      </SimpleForm>
    </Create>
  );
};

const PostActions = ({
    bulkActions,
    basePath,
    currentSort,
    displayedFilters,
    exporter,
    filters,
    filterValues,
    onUnselectItems,
    resource,
    selectedIds,
    showFilter,
    total
}) => (
    <CardActions>
        {bulkActions && React.cloneElement(bulkActions, {
            basePath,
            filterValues,
            resource,
            selectedIds,
            onUnselectItems,
        })}
        {filters && React.cloneElement(filters, {
            resource,
            showFilter,
            displayedFilters,
            filterValues,
            context: 'button',
        }) }
        {/* Add your custom actions */}
        <CreateButton basePath="services" />
    </CardActions>
);

export const SubscriptionsList = props => (
    <List {...props}
      filter={{ account: localStorage.getItem("account_id") }}
      title="Servicios"
      filters={<SubscriptionsFilter />}
      bulkActionButtons={false}
      actions={<PostActions />}
      >
        <Datagrid rowStyle={rowStyle} rowClick="expand" expand={<SubscriptionEdit/>}>
            <ReferenceField linkType={false} source="service_id" reference="services" label="Servicio">
              <TextField source="name" />
            </ReferenceField>
            <DateField source="created" label="Solicitado" locales="es-ES"/>
            <ReferenceField linkType={false} source="state_id" reference="subscriptions_state" label="">
              <FunctionField render={function render(record) {

                  if (record.codename === "awaiting_deactivation") {
                    return(<Chip
                              label="Servicio activo"
                              color="primary"
                            />);
                  }

                  if (record.codename === "awaiting_activation" || record.codename === "awaiting_initialization_approval") {
                    return(<Chip
                              label="Servicio inactivo"
                              color="error"
                            />);
                  }

                  switch(record.codename) {
                    case ("initialized"):
                      return(<Chip
                                label="Servicio activo"
                                color="primary"
                              />);
                    case ("activated"):
                      return(<Chip
                                label="Servicio activo"
                                color="primary"
                              />);
                    case "deactivated":
                      return(<Chip
                                label="Servicio inactivo"
                                color="error"
                              />);
                    case "rejected":
                      return(<Chip
                                label="Su solicitud fué rechazada, puede volver a solicitar el servicio"
                                color="error"
                              />);
                    default:
                      return(<Chip
                                label="Servicio inactivo"
                                color="error"
                              />);
                  }
                }} />
            </ReferenceField>
            <ReferenceField linkType={false} source="state_id" reference="subscriptions_state" label="">
              <FunctionField render={function render(record) {
                  if (record.codename === "awaiting_deactivation") {
                    return(<Chip
                              label="Aguardando desactivación"
                              color="secondary"
                            />);
                  }

                  if (record.codename === "awaiting_activation" || record.codename === "awaiting_initialization_approval") {
                    return(<Chip
                              label="Aguardando activación"
                              color="secondary"
                            />);
                  }
                }} />
            </ReferenceField>
        </Datagrid>
    </List>
);
