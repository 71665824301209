// in src/ForgotPassword.js
import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { Title } from 'react-admin';
import Typography from '@material-ui/core/Typography';

const AlternativeMailSuccess = () =>  (
      <Card>
          <Title title="Email alternativo confirmado" />
          <CardContent>
            <Typography>
              Se ha confirmado su email alternativo con éxito
            </Typography>
          </CardContent>
      </Card>
);

export default AlternativeMailSuccess;
