// in src/authProvider.js
import { AUTH_LOGIN, AUTH_LOGOUT, AUTH_CHECK, AUTH_ERROR } from 'react-admin';
import { stringify } from 'query-string';

const api_uri = process.env.REACT_APP_SERVER_URL;
const client_id = process.env.REACT_APP_CLIENT_ID;
const client_secret = process.env.REACT_APP_CLIENT_SECRET;

function getAccountID(access_token) {
  const request = new Request(api_uri + '/api/v2/me/', {
    method: 'GET',
    headers: new Headers({
      Accept: 'application/json',
      'Authorization': `Bearer ` + access_token
    }),
  })

  return request;
}

function getPermissions() {
  const account_types = localStorage.getItem('account_types_id');
  const query = {
    account_types: account_types,
  };
  const request = new Request(api_uri + '/api/v2/services_permissions_status/?' + stringify(query), {
    method: 'GET',
    headers: new Headers({
      Accept: 'application/json',
      'Authorization': `Bearer ` + localStorage.getItem('access_token')
    }),
  })

  return request;
}

export default (type, params) => {
  console.log('authprovider', type, params)
  if (type === AUTH_LOGIN) {
    const { username, password } = params;
    const grant_type = 'password';
    const request = new Request(api_uri + '/o/token/', {
      method: 'POST',
      body: stringify({ grant_type, username, password }),
      headers: new Headers({
        'Content-Type': 'application/x-www-form-urlencoded',
        'Authorization': `Basic ${btoa(`${client_id}:${client_secret}`)}`
      }),
    })
    return fetch(request)
      .then(response => {
        if (response.status === 400) {
          throw new Error("Usuario o Clave incorrectos");
        }
        if (response.status < 200 || response.status >= 300) {
          throw new Error(response.statusText);
        }
        return response.json();
      })
      .then(({ access_token }) => {
        localStorage.setItem('access_token', access_token);
        return access_token;
      })
      .then(access_token =>
        fetch(getAccountID(access_token))
          .then(response => {
            if (response.status < 200 || response.status >= 300) {
              throw new Error(response.statusText);
            }
            return response.json();
          })
          .then(response => {
            localStorage.setItem('account_id', response.results[0].account.id);
            localStorage.setItem('account_types_id', response.results[0].account.account_types_id);
            return access_token;
          })
          .then(access_token =>
            fetch(getPermissions(access_token))
              .then(response => {
                if (response.status < 200 || response.status >= 300) {
                  throw new Error(response.statusText);
                }
                return response.json();
              })
              .then(response => {
                const permissions_status = response.results;

                var services_permissions = {};

                for (var i in permissions_status) {
                  if (!Array.isArray(services_permissions[permissions_status[i].service_id])) {
                    services_permissions[permissions_status[i].service_id] = [];
                  }

                  if (permissions_status[i].is_active && !services_permissions[permissions_status[i].service_id].includes(permissions_status[i].permission_id)) {
                    services_permissions[permissions_status[i].service_id].push(permissions_status[i].permission_id);
                  }
                }

                localStorage.setItem('services_permissions', JSON.stringify(services_permissions));
              })
          ));
  }
  if (type === AUTH_LOGOUT) {
    localStorage.removeItem('access_token');
    localStorage.removeItem('account_id');
    localStorage.removeItem('account_types_id');
    localStorage.removeItem('services_permissions');
    //localStorage.removeItem('role');
    return Promise.resolve();
  }
  if (type === AUTH_ERROR) {
    return Promise.resolve();
  }
  if (type === AUTH_CHECK) {
    return localStorage.getItem('access_token') ? Promise.resolve() : Promise.reject();
  }
  return Promise.reject('Unknown method');
};
