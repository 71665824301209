// in src/customRoutes.js
import React from 'react';
import { Route } from 'react-router-dom';
import Password from './Password';
import ForgotPassword from './ForgotPassword';
import AlternativeMailSuccess from './AlternativeMailSuccess';
import CustomNewPasswordPage from './RequestNewPasswordPage';

export default [
    <Route exact path="/alternative_email/success" component={AlternativeMailSuccess} />,
    <Route exact path="/password/forgot" noLayout component={CustomNewPasswordPage} />,
    <Route exact path="/password/forgot/:tempToken" noLayout component={ForgotPassword} />,
    <Route exact path='/password' component={Password} />
];
