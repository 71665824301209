// in src/App.js
import React from 'react';
import { Admin, Resource, resolveBrowserLocale } from 'react-admin';
import MyLayout from './MyLayout';
import dataProvider from './dataProvider';
import authProvider from './authProvider'
import customRoutes from './customRoutes';
import { AccountShow, AccountsEdit } from './accounts'
import { SubscriptionsCreate, SubscriptionsList } from './subscriptions';
import { ServicesList } from './services';
import Dashboard from './Dashboard';
import LoginPage from './LoginPage';

import spanishMessages from 'aor-language-spanish';
import englishMessages from 'ra-language-english';

const messages = {
    es: spanishMessages,
    en: englishMessages
};
const i18nProvider = locale => messages[locale];

const App = () => (
  <Admin
    loginPage={LoginPage}
    locale={resolveBrowserLocale()}
    dashboard={Dashboard}
    i18nProvider={i18nProvider}
    appLayout={MyLayout}
    customRoutes={customRoutes}
    dataProvider={dataProvider}
    authProvider={authProvider}>
        <Resource name="accounts" show={AccountShow} edit={AccountsEdit} />
        <Resource name="accounttypes" />
        <Resource name="services" list={ServicesList} />
        <Resource name="idtypes" />
        <Resource name="services_permissions" />
        <Resource name="subscriptions" list={SubscriptionsList} create={SubscriptionsCreate} />
        <Resource name="subscriptions_state" />
    </Admin>
);

export default App;
