// ForgotPassword.js
import React from 'react';
import Login from './Login';
import ForgotPasswordForm from './ForgotPasswordForm';
import { withStyles } from '@material-ui/core/styles';

const styles = ({
    main: { background: '#333' },
});

const CustomForgotPasswordForm = withStyles({
    button: { background: '#F15922' },
})(ForgotPasswordForm);

const CustomForgotPasswordPage = props => (
    <Login backgroundImage={false}
        loginForm={<CustomForgotPasswordForm initialValues={{ tempToken: props.match.params.tempToken }} />}
        {...props}
    />
);

export default withStyles(styles)(CustomForgotPasswordPage);
