// in src/Password.js
import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { Title, SimpleForm, TextInput } from 'react-admin';

const Password = () => (
    <Card>
        <Title title="Cambio de clave" />
        <CardContent>
        </CardContent>
          <SimpleForm>
            <TextInput label="Password" source="password" type="password"/>
            <TextInput label="Password confirm" source="password_confirm" type="password"/>
          </SimpleForm>
    </Card>
);

export default Password;
